<template>
    <a-drawer width="800" :visible="visible" placement="right" @close="() => $emit('close')">
        <div>{{ content }}</div>
    </a-drawer>
</template>

<script>
import { Drawer as ADrawer } from 'ant-design-vue'
export default {
    name: 'ContentDrawer',
    components: {
        ADrawer
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        content: [String, Object, Array]
    }
}
</script>

<style>

</style>
