<template>
    <div>
        <div class="test-head">
            <a-button type="primary" @click="drawerVisible = true">展示配置</a-button>
            <statistics />
        </div>

        <div>
            <page v-if="renderType === 1" :layout-code="pageLayoutCode" />
            <detail-page v-if="renderType === 2"  :page-params="detailPageParams" />
            <dialog-test v-if="renderType === 3" :render-params="dialogRenderParams" />
        </div>


        <config-drawer :visible="drawerVisible" @close="drawerVisible = false" @render="createPage"  />
    </div>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import Page from '@/weights/PageTemplate/Page.vue'
import DetailPage from '@/weights/PageTemplate/Detail.vue'
import DialogTest from '@/weights/PageTemplate/DialogTest.vue'

import ConfigDrawer from '@/weights/TestComponents/ConfigDrawer.vue'

import Statistics from '@/weights/TestComponents/Statistics.vue'

import { CLEAR_PAGE_F_API } from '@/store/mutation-types'


// import json from './test'
// gov_risk_management_400

// {"id":"1493410471314980864","layoutCode":"gov_enterprise_30","operationType":"30","dynamicType":"0"}


export default {
    name: 'TestPage',
    components: {
        AButton,
        Page,
        DetailPage,
        DialogTest,
        ConfigDrawer,
        Statistics
    },
    data() {
        return {
            detailPageParams: '',
            renderType: '',
            dialogRenderParams: null,
            drawerVisible: false,
            pageLayoutCode: '',
            templateData: ''
        }
    },
    methods: {
        createPage(config) {
            const { type, requireParams, requireType, layoutCode } = config
            const params = requireParams ? JSON.parse(requireParams) : {}
            this.$store.commit(CLEAR_PAGE_F_API)
            this.renderType = 0

            if (+type === 1) {
                this.pageLayoutCode = layoutCode
            }

            // 详情页事件参数处理
            if (+type === 2) {
                params.dynamicType = requireType === '3' ? 1 : 0
                params.operationType = +requireType
                params.layoutCode = layoutCode
                params.eventCode = layoutCode
                this.detailPageParams = params
            }


            // 弹框展示参数处理
            if (+type === 3) {
                const event = {
                    eventType: 3,
                    interfaceType: 3,
                    permissionCode: 'globalOperation'
                }
                event.dynamicType = requireType === '3' ? 1 : 0
                event.operationType = +requireType
                event.layoutCode = layoutCode
                event.eventCode = layoutCode

                this.dialogRenderParams = {
                    event,
                    params
                }
            }

            this.$nextTick(() => {
                this.renderType = +type
            })
        }
    }

}
</script>

<style lang="scss" scoped>
    .test-head {
        display: flex;
        padding: 5px;
        span {
            margin-left: 20px;

        }
    }
</style>
