<template>
    <a-drawer width="800" :visible="visible" placement="right" @close="() => $emit('close')">
        <div class="drawer-title">FieldList({{ fieldList.length }})</div>

        <a-descriptions
            v-for="field, fieldIndex in fieldList"
            :key="fieldIndex"
            bordered
            :title="field.fieldName + '/' + field.fieldCode">
            <a-descriptions-item
                v-for="key, keyIndex in fieldKeys"
                :key="keyIndex"
                :label="key">
                {{ field[key] }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>

<script>
import { Drawer as ADrawer, Descriptions as ADescriptions } from 'ant-design-vue'
export default {
    name: 'FieldDrawer',
    components: {
        ADrawer,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        fieldList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            fieldKeys: [
                'dataType', 'componentType', 'allowEditing', 'nullAllowed', 'unit', 'formula'
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.drawer-title {
    font-size: 20px;
    padding: 10px 0;
}
</style>
