<template>
    <div class="test-config-history">
        <div
            v-for="config in list"
            :key="config.id"
            class="test-config-history__item"
            @click="() => changeConfig(config)">
            {{ config.layoutCode }}
        </div>
    </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
export default {
    name: 'ConfigHistory',
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            const list = JSON.parse(localStorage.getItem('testConfig') || '[]')
            this.list = list

        },
        setData(config) {

            // 判断历史中是否有相同的配置
            const equalIndex = this.list.findIndex(history => {
                if (config.id === history.id) return true

                let index = 0
                try {

                    if (config.type === history.type) index++
                    if (config.requireType === history.requireType) index++
                    if (config.layoutCode === history.layoutCode) index++

                    if (config.requireParams === history.requireParams) {
                        index++
                    } else {
                        const q1 = JSON.parse(config.requireParams)
                        const q2 = JSON.parse(history.requireParams)
                        if (isEqual(q1, q2)) index++
                    }


                    return index === 4

                } catch (error) {
                    console.log(error)
                    return false
                }
            })

            if (equalIndex === -1) {
                this.list.unshift(config)
                config.id = Date.now()
                localStorage.setItem('testConfig', JSON.stringify(this.list))
            }

        },
        clearList() {
            this.list = []
            localStorage.setItem('testConfig', '')
        },
        changeConfig(config) {
            this.$emit('change-config', config)
        },
        getFirstConfig() {
            return this.list.length > 0 ? this.list[0] : null
        }
    }
}
</script>

<style lang="scss" scoped>
.test-config-history {
    display: flex;
    flex-wrap: wrap;

    &__item {
        margin-right: 10px;
        color: rgb(0, 132, 255);
        cursor:pointer
    }
}
</style>
