<template>
    <a-drawer width="800" :visible="visible" placement="right" @close="closeHandle">
        <div class="drawer-title">Event</div>

        <a-collapse>
            <template v-for="event, index in eventList">
                <a-collapse-panel :key="index" :header="getCollapsePanelHeader(event)">
                    <div>
                        <a-descriptions title="其他参数" :bordered="true">
                            <template v-for="key, keyIndex in eventDetailKeys">
                                <a-descriptions-item :key="keyIndex" :label="key">
                                    {{ event[key] }}
                                </a-descriptions-item>
                            </template>
                        </a-descriptions>

                        <div class="drawer-title">
                            queryCriteria（{{ getQueryCriteriaListLen(event) }}）
                        </div>

                        <template v-for="queryCriteria, queryCriteriaIndex in event.queryCriteriaList || []">
                            <a-descriptions
                                :key="queryCriteriaIndex"
                                :bordered="true"
                                :title="getDescriptonsTitle(queryCriteria)"
                            >
                                <a-descriptions-item
                                    v-for="key, keyIndex in queryCriteriaKeys"
                                    :key="keyIndex"
                                    :label="key"
                                >
                                    {{ queryCriteria[key] }}
                                </a-descriptions-item>
                            </a-descriptions>
                        </template>
                    </div>
                </a-collapse-panel>
            </template>
        </a-collapse>
    </a-drawer>
</template>

<script>
import { Drawer as ADrawer, Collapse as ACollapse, Descriptions as ADescriptions } from 'ant-design-vue'
export default {
    name: 'EventDrawer',
    components: {
        ADrawer,
        ACollapse,
        ACollapsePanel: ACollapse.Panel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    props: {
        eventList: {
            type: Array,
            default: () => []
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            eventDetailKeys: [
                'method', 'dynamicType', 'operationType', 'eventType', 'interfaceType'
            ],
            queryCriteriaKeys: [
                'fieldCode', 'fieldName', 'queryType', 'dataType', 'componentType', 'queryState'
            ]
        }
    },
    methods: {
        getCollapsePanelHeader(event) {
            return `${event.eventName || '加载事件'}(${event.eventCode})`
        },
        getQueryCriteriaListLen(event) {
            return event.queryCriteriaList?.length || 0
        },
        getDescriptonsTitle(queryCriteria = {}) {
            return `${queryCriteria.fieldName}/${queryCriteria.fieldCode}`
        },
        closeHandle() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.drawer-title {
    font-size: 20px;
    padding: 10px 0;
}
</style>
