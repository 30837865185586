<template>
    <div v-if="currentStatistics">
        <a-tree :tree-data="treeData">
            <template slot="custom-title" slot-scope="item">
                <span>{{ item.layoutCode }}{{ item.componentType ? ` / ${item.componentType}` : '' }}</span>

                <a-button
                    type="link"
                    @click="() => openDrawer(1, item.eventList)">
                    事件数量：{{ item.eventList.length }}
                </a-button>

                <a-button
                    type="link"
                    @click="() => openDrawer(2, item.fieldList)">
                    field数量：{{ item.fieldList.length }}
                </a-button>
                <a-button
                    type="link">
                    children数量：{{ item.children.length }}
                </a-button>
                <a-button
                    type="link"
                    @click="() => openDrawer(3, item.content)">
                    数据
                </a-button>
            </template>
        </a-tree>
        <a-button @click="showParentLayout">切换上一级layout</a-button>
        <a-button @click="showChildLayout">切换下一级layout</a-button>
        <!-- 事件属性 -->
        <event-drawer :visible="eventDrawerVisible" :event-list="eventList" @close="eventDrawerVisible = false" />
        <!-- field属性 -->
        <field-drawer :visible="fieldDrawerVisible" :field-list="fieldList" @close="fieldDrawerVisible = false" />
        <!-- content属性 -->
        <content-drawer :visible="contentDrawerVisible" :content="content" @close="contentDrawerVisible = false" />
    </div>
</template>

<script>
import { Tree as ATree, Button as AButton } from 'ant-design-vue'
import EventDrawer from './EventDrawer.vue'
import FieldDrawer from './FieldDrawer.vue'
import ContentDrawer from './ContentDrawer.vue'
export default {
    name: 'StatisticsTree',
    components: {
        AButton,
        ATree,
        EventDrawer,
        FieldDrawer,
        ContentDrawer
    },
    data() {
        return {
            currentStatistics: null,
            eventDrawerVisible: false,
            fieldDrawerVisible: false,
            contentDrawerVisible: false,
            eventList: [],
            fieldList: [],
            content: [],
            currentIndex: 0
        }

    },
    computed: {
        pageFApiList() {
            return this.$store.getters.pageFApiList
        },
        treeData() {
            return [this.currentStatistics]
        },
        currentFApi() {
            const { pageFApiList, currentIndex } = this
            if (currentIndex < 0) {
                return null
            }
            return pageFApiList[currentIndex]
        }
    },
    watch: {
        currentFApi: {
            handler(currentFApi) {
                if (currentFApi) {
                    setTimeout(() => {
                        const rule = currentFApi?.rule[0] || []
                        const data = this.createDataTree(rule)
                        this.currentStatistics = data
                    }, 2000)
                }

            },
            immediate: true
        },
        pageFApiList: {
            handler(pageFApiList) {
                // this.currentFApi = pageFApiList.length > 0 ? pageFApiList[pageFApiList.length - 1] : null
                this.currentIndex = pageFApiList.length > 0 ? pageFApiList.length - 1 : -1
            },
            immediate: true
        }
    },
    methods: {
        createDataTree(tree) {
            if (!tree) return null
            let currentChildren = []
            const { layoutCode, eventList = [], data, componentType, children = [], id } = tree
            if (children && children.length > 0) {
                currentChildren = children.map(child => this.createDataTree(child))
            }


            return {
                scopedSlots: {
                    title: 'custom-title'
                },
                layoutCode,
                eventList: eventList || [],
                componentType,
                fieldList: data?.fieldList || [],
                children: currentChildren || [],
                key: id,
                content: data?.dataString || ''
            }
        },
        openDrawer(type, data) {
            switch (type) {
                case 1:
                    this.eventDrawerVisible = true
                    this.eventList = data
                    break
                case 2:
                    this.fieldDrawerVisible = true
                    this.fieldList = data
                    break
                case 3:
                    this.contentDrawerVisible = true
                    this.content = data
                    break
            }
        },
        showParentLayout() {
            if (this.currentIndex <= 0) {
                this.$message.error('已经是最上层layout')
                return
            }
            this.currentIndex -= 1
        },
        showChildLayout() {
            if (this.currentIndex >= this.pageFApiList.length - 1) {
                this.$message.error('已经是最下层layout')
                return
            }
            this.currentIndex += 1
        }
    }
}
</script>

<style>

</style>
