<template>
    <a-drawer :visible="visible" height="600" placement="top" @close="closeHandle">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }" layout="horizontal">
            <a-form-item label="生成选项">
                <a-raio-group v-model="type">
                    <a-radio-button value="1">
                        一级页面
                    </a-radio-button>
                    <a-radio-button value="2">
                        详情页
                    </a-radio-button>
                    <a-radio-button value="3">
                        弹框
                    </a-radio-button>
                </a-raio-group>
            </a-form-item>
            <a-form-item label="layoutCode">
                <a-input v-model="layoutCode" />
            </a-form-item>
            <a-form-item label="请求方式">
                <a-raio-group v-model="requireType">
                    <a-radio-button value="60">
                        页面加载（loadLayout）
                    </a-radio-button>
                    <a-radio-button value="1">
                        事件触发（activeEvent）
                    </a-radio-button>
                    <a-radio-button value="3">
                        动态加载 （dynamicEvent）
                    </a-radio-button>
                </a-raio-group>
            </a-form-item>
            <a-form-item label="请求参数">
                <a-text-area v-model="requireParams" :rows="16" />
            </a-form-item>
            <a-form-item :wrapper-col="{offset: 4}">
                <a-button type="primary" @click="renderPage">渲染页面</a-button>
                <a-button  @click="clearHistory">清空历史</a-button>
            </a-form-item>
            <a-form-item label="历史记录">
                <config-history ref="history" @change-config="changeConfig" />
            </a-form-item>
        </a-form>
    </a-drawer>
</template>

<script>
import { Button as AButton, Input as AInput, FormModel as AForm, Radio as ARaio, Drawer as ADrawer } from 'ant-design-vue'
import ConfigHistory from './ConfigHistory.vue'
export default {
    name: 'ConfigDrawer',
    components: {
        AButton,
        AInput,
        AForm,
        AFormItem: AForm.Item,
        ARadioButton: ARaio.Button,
        ARaioGroup: ARaio.Group,
        ATextArea: AInput.TextArea,
        ADrawer,
        ConfigHistory
    },
    props: {
        visible: {
            type: Boolean,
            defualt: true
        }
    },
    data() {
        return {
            type: '3',
            requireType: '1',
            requireParams: ``,
            layoutCode: ''
        }
    },
    watch: {
        visible: {
            handler(visible) {
                if (visible) {
                    this.getFirstConfig()
                }
            }
        }
    },
    mounted() {
    },
    methods: {
        closeHandle() {
            this.$emit('close')
        },
        renderPage() {
            const { type, requireParams, requireType, layoutCode } = this
            const config = {
                type,
                requireParams,
                requireType,
                layoutCode
            }
            this.$emit('render', config)
            this.$refs.history.setData(config)
        },
        changeConfig(data) {
            const { type, requireParams, requireType, layoutCode } = data
            Object.assign(this, {
                type,
                requireParams,
                requireType,
                layoutCode
            })

        },
        clearHistory() {
            this.$refs.history.clearList()
        },
        getFirstConfig() {
            if (!this.layoutCode) {
                this.$nextTick(() => {

                    const config = this.$refs.history.getFirstConfig()
                    if (config) {
                        this.changeConfig(config)
                    }
                })
            }


        }
    }
}
</script>

<style lang="scss" scoped>
    .ant-form-item {
        width: 50%;
        display: inline-block;
    }
</style>
