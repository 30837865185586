<script>
import initData from '@mixins/initData'
export default {
    name: 'PageTemplate',
    components: {},
    mixins: [initData],
    props: {
        renderParams: {
            type: Object
        }
    },
    data() {
        return {
            templateData: {
                layoutType: 20,
                layoutCode: 'gov_check_plan_40',
                layoutName: '弹框测试',
                componentType: 'table_1',
                children: null,
                data: {
                    dataCode: 'gov_check_plan_40',
                    dataFormatType: 40,
                    dataName: '检查计划(政府端)',
                    fieldList: [],
                    dataString: '{"records":[],"total":4,"size":10,"current":1,"orders":[],"optimizeCountSql":true,"searchCount":true,"pages":1}'
                },
                eventList: []
            }

        }
    },
    watch: {
        templateData: {
            handler(templateData) {
                if (templateData) {
                    // NOTICE: Here's local mock operation!
                    this.template = templateData
                    this.loading = false
                }
            },
            immediate: true,
            deep: true
        },
        renderParams: {
            handler(renderParams) {
                if (renderParams) {
                    const { event, params } = renderParams
                    setTimeout(() => {
                        this.openDialog(event, params)
                    }, 1000)
                }
            },
            immediate: true
        }
    },
    methods: {
        openDialog(event, params) {
            const fApi = this.$store.getters.pageFApi
            const el = fApi.el('gov_check_plan_40')
            el.$refs['contentItem'].handleButtonClick(event, params)
        }
    }
}
</script>
